.blog-head-section-container {
    width: 95vw;
    height: 218.8px;
    position: relative;
    top: 1200px;



}

.blog-head-bg-container {
    width: 90vw;
    height: 154.8px;

}

.blog-head-text-container {
    height: 112.8px;
    width: 683.33px;
    padding: 10px;
}

.blog-head-card-container {
    height: 529.33px;
    width: 95vw;
    position: absolute;
    top: 1250px;

}




@media screen and (max-width: 600px) {
    .blog-head-section-container {
        width: 100vw;
        height: auto;
        /* height: 232.2px; */
        /* position: relative; */
        top: 500rem;
        display: flex;
        flex-direction: column;
        justify-content: center;



    }


    .blog-head-bg-container {
        width: 90vw;
        height: 184.8px;


    }

    .blog-head-text-container {
        height: 87px;
        width: 320px;
        text-align: center;
        padding: 10px;


    }

    .blog-head-button-container {
        height: 72px;
        width: 320px;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .blog-head-card-container {
        height: auto;
        /* height: 170vh; */
        width: 100vw;
        position: absolute;
        top: 500rem;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;




    }
}


@media screen and (min-width: 700px) {
    .blog-head-section-container {
        width: 100vw;
        height: auto;
        /* height: 232.2px; */
        position: relative;
        top: 300rem;
        display: flex;
        flex-direction: column;
        justify-content: center;



    }

    .blog-head-card-container {
        height: auto;
        /* height: 170vh; */
        width: 100vw;
        position: absolute;
        top: 300rem;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;




    }


}

@media screen and (min-width: 1024px) {

    .blog-head-section-container {
        width: 95vw;
        height: 218.8px;

        top: 200rem;



    }

    .blog-head-bg-container {
        width: 90vw;
        height: 154.8px;

    }

    .blog-head-text-container {
        height: 112.8px;
        width: 683.33px;
        padding: 10px;
    }

    .blog-head-card-container {
        height: 529.33px;
        width: 95vw;
        position: absolute;
        top: 205rem;

    }


}