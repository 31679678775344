.hero-Layout {
    display: flex;
    justify-content: center;
    align-items: center;

}

@media screen and (max-width: 1024px) {

    .hero-Layout {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;



    }



}

@media screen and (max-width: 600px) {
    .hero-Layout {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        padding: 20px;


    }


}