.testimonial-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 409.6px;
    position: relative;
    top: 1200px;
    width: 100%;



}

.testimonials-layout {
    width: 1015px;
    height: 297.6px;


}

.testimonials-text {
    width: 435px;
    height: 297.6px;

}

.testimonial-card-container {

    height: 265.6px;
    width: 100%;
    /* width: 462px; */

}




@media screen and (max-width: 600px) {
    .testimonial-card-container {

        /* height: 265.6px; */
        height: auto;
        width: 100%;
        /* width: 311px; */

    }

    .testimonial-container {
        width: 100%;
        /* height: 409.6px; */
        height: auto;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 475rem;



    }

    .testimonials-layout {
        width: 100%;
        height: auto;
        /* height: 484.2px; */

        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;

    }

    .testimonials-text {
        width: 351px;
        height: 117.8px;

    }

    .swiper-container {
        width: 300px;

    }
}

@media screen and (min-width: 700px) {
    .testimonial-card-container {

        /* height: 265.6px; */
        height: auto;
        width: 100%;
        /* width: 311px; */

    }

    .testimonial-container {
        width: 100%;
        /* height: 409.6px; */
        height: auto;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 290rem;


    }

    .testimonials-layout {
        width: 100%;
        height: auto;
        /* height: 484.2px; */

        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;

    }

    .testimonials-text {
        width: 100%;
        /* width: 351px; */
        height: auto;
        /* height: 117.8px; */

    }

    .swiper-container {
        width: 700px;

    }
}

@media screen and (min-width: 1024px) {
    .testimonial-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        height: auto;
        position: absolute;
        top: 170rem;
        width: 100%;



    }

    .testimonials-layout {
        width: 95%;
        height: auto;
        /* height: 297.6px; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

    }

    .testimonials-text {
        width: 435px;
        height: 297.6px;


    }

    .testimonial-card-container {

        /* height: 265.6px; */
        height: auto;
        width: 90%;
        /* width: 311px; */


    }

    .swiper-container {
        width: 1024px;
    }

}