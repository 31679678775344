@media screen and (max-width: 600px) {
    .whyus-layout-sm {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 1900px;



    }
}

@media screen and (min-width: 700px) {

    .whyus-layout-sm {
        width: 100%;
        height: 1500px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: black
    }



}

@media screen and (min-width: 1024px) {

    .whyus-layout-sm {
        width: 100%;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;




    }




}