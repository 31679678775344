.background-successbg {
    height: 614px;
    width: 900px;
    background-color: rgb(33, 33, 33);


}

@media screen and (max-width: 600px) {
    .background-successbg-sm {
        height: 400px;
        width: 311px;
    }

    .text-h1-sm {
        font-size: large;
    }

    .text-h2-sm {
        font-size: x-large;
    }


}

@media screen and (max-width: 1000px) {
    .background-successbg {
        height: 614px;
        width: 100%;
        background-color: rgb(33, 33, 33);

    }

    .text-h1-sm {
        font-size: large;
    }

    .text-h2-sm {
        font-size: x-large;
    }


}