.influencers-container {
    width: 100%;
    height: auto;
}

.influencers-layout {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

@media screen and (max-width: 912px) {

    .influencers-layout {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;

    }



}

@media screen and (max-width: 600px) {

    .influencers-layout {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
    }

    .influencers-container {
        width: 100%;
        height: fit-content;

    }

    .total-height {
        width: 100%;
        height: fit-content;
    }

}