/* .whyus-text-container {
    height: 75vh;
    width: 75vh;

} */
.whyus-text-container {
    height: 100vh;
    width: 90%;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;


}

.barCompleted {
    background-color: orange;
    width: 80%;
}

.barCompleted2 {
    background-color: blue;
    width: 40%;
}

.barCompleted3 {
    background-color: pink;
    width: 90%;
}

.containerbar {
    background-color: gainsboro;
    width: 300px;
}

@media screen and (max-width: 600px) {
    .whyus-text-container {
        height: 100%;
        width: 100%;
        padding: 10px;
        margin-top: 20px;


    }

    .whyus-text-heading1-sm {
        margin-bottom: 20px;
        text-align: center;

    }

    .whyus-text-heading2-sm {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;

    }
}

@media screen and (min-width: 700px) {
    .whyus-text-container {
        height: 100vh;
        width: 90%;
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: center;
        position: relative;
        top: 300px;
        text-align: center;

    }





}


@media screen and (min-width: 764px) {
    .whyus-text-container {
        height: 100vh;
        width: 90%;
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: center;
        position: relative;
        top: 40rem;

    }


}


@media screen and (min-width: 1024px) {

    .whyus-text-container {
        height: 70vh;
        width: 45vw;
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 10rem;




    }




}


/* 
@media screen and (max-width: 992px) {

    .whyus-text-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
    }

    .whyus-text-heading1-sm {
        margin-bottom: 20px;
        text-align: center;
    }

    .whyus-text-heading2-sm {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
} */