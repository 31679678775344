.pricing-card-conatiner {
    height: 362px;
    /* width: 711.4px; */
    width: 20vw;



}

.pricing-card-face-container {
    height: 405px;
    width: 330px;


}

@media screen and (max-width: 600px) {
    .pricing-card-conatiner {
        height: auto;
        /* height: 570px; */
        width: 351px;
        display: flex;
        justify-content: center;
        align-items: center;



    }

    .pricing-card-face-container {
        height: 324px;
        width: 327.2px;

    }


}

@media screen and (min-width: 700px) {

    .pricing-card-conatiner {
        height: auto;
        /* width: 711.4px; */
        width: 100%;



    }

    .pricing-card-face-container {
        height: 405px;
        width: 330px;


    }
}

@media screen and (min-width: 1024px) {
    .pricing-card-conatiner {
        height: 362px;
        /* width: 711.4px; */
        width: 20vw;



    }

    .pricing-card-face-container {
        height: 405px;
        width: 330px;


    }


}