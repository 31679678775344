.text-layout {
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: 90%;
    height: 100%;
    margin-top: 150px;

    text-align: center;


}

@media screen and (max-width: 600px) {
    .text-layout {
        padding: 10px;
        margin-left: 10px;
        margin-right: 10px;
        width: 90%;
        height: 100%;



    }

    .text-heading {
        font-size: 24px;
    }

    .about-points {
        padding-top: 10px;

    }



}

@media screen and (min-width: 800px) {

    .text-layout {
        padding: 10px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 150px;
        width: 90%;
        height: 100%;
        text-align: center;


    }



}

@media screen and (min-width: 1024px) {

    .text-layout {
        padding: 10px;
        margin-left: 10px;
        margin-right: 10px;
        width: 50%;
        height: 100%;



    }


}