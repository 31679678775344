.hero-left-position {
    z-index: 1;
}

.hero-left-container {

    width: 50%;
    height: 468.8px;
    padding: 2%;

}

.btn-hero-center-sm {
    display: flex;
    justify-content: flex-start;
    margin-top: 5vh;
    padding-right: 5%;
    width: 12rem;

}

@media screen and (max-width: 1000px) {
    .hero-left-container {
        width: 100%;

    }

    .hero-text-layout-sm {
        display: flex;
        justify-content: center;
        align-items: flex-start;

    }

    .text-alignment-tab {
        text-align: center;
    }




}


@media screen and (max-width: 600px) {

    .hero-left-container {
        width: 100%;
        height: fit-content;

    }

    .btn-hero-center-sm {
        display: flex;
        justify-content: flex-start;
        margin-top: 5vh;
        padding-right: 10%;
        width: 12rem;

    }

    .hero-text-layout-sm {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;


    }

    .hero-top-devider-sm {
        border-top: 2px solid gray;
        margin-top: 30px;

    }

}