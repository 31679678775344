.service-container {
    max-width: 100%;
    max-height: auto;
    background-color: white;

}

.service-position {
    height: fit-content;
    top: 1000px;
}

@media screen and (max-width: 600px) {

    .service-position {
        height: fit-content;
        top: 3000px;

    }
}

@media screen and (min-width: 700px) {

    .service-position {
        height: fit-content;
        top: 100rem;

    }
}

@media screen and (min-width: 1000px) {

    .service-position {
        height: fit-content;
        top: 60rem;

    }
}