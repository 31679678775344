.container-right {
    height: 725px;
    width: 575.9px;
}


.top-img-size {
    height: 159.84px;
    width: 159.89px;

}

.main-img {
    width: 100%;
    height: 675.9px;
}

.main-img-bg {
    /* position: absolute;
    top: 200px;
    right: 100px; */
    height: 426px;
    width: 500px;

}

.main-img-position {
    position: absolute;
    top: -60px;
    right: 0px;
}

.bottom-img-size {
    height: 159.84px;
    width: 159.89px;
}

@media screen and (max-width: 1000px) {

    .container-right {
        height: 749px;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .all-img-position-layout-sm {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .main-img-bg {
        /* position: absolute;
        top: 200px;
        right: 100px; */
        height: 427px;
        width: 583px;

    }

    .top-img-size {
        height: 174px;
        width: 174px;

    }


    .main-img-position {
        position: absolute;
        top: -60px;
        right: 0px;
    }

    .bottom-img-size {
        height: 174px;
        width: 174px;
    }

    .top-img-position {
        position: absolute;
        top: -30px;
        left: -20px;
        z-index: 1;

    }

    .bottom-img-position {
        position: absolute;
        top: 290px;
        right: -20px;
        z-index: 1;

    }

}


@media screen and (max-width: 600px) {
    .container-right {
        height: 430px;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .all-img-position-layout-sm {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .main-img-bg {
        /* position: absolute;
        top: 200px;
        right: 100px; */
        height: 40%;
        width: 80%;

    }

    .top-img-size {
        height: 35%;
        width: 35%;

    }


    .main-img-position {
        position: absolute;
        top: -30px;
        right: 0px;
    }

    .bottom-img-size {
        height: 35%;
        width: 35%;
    }

    .top-img-position {
        position: absolute;
        top: -30px;
        left: -20px;
        z-index: 1;

    }

    .bottom-img-position {
        position: absolute;
        top: 230px;
        right: -20px;
        z-index: 1;

    }
}