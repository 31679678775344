.blog-card-container {

    width: 470px;
    height: 600.3px;



}

.img-size {

    width: 470px;
    height: 350px;


}


@media screen and (max-width: 600px) {
    .blog-card-container {

        width: 90vw;
        height: 600.3px;
        padding: 20px;



    }

    .img-size {

        width: 80vw;
        height: 350px;


    }
}