.swiper-container {
    width: 100%;


}

swiper-image {
    max-width: 120px;
}



@media screen and (max-width: 600px) {
    .swiper-container {
        width: 90%;


    }

    swiper-image {
        max-width: 120px;
    }
}

@media screen and (min-width: 700px) {
    .swiper-container {
        width: 100%;


    }

    swiper-image {
        max-width: 120px;
    }
}





@media screen and (min-width: 1024px) {
    .swiper-container {
        width: 1024px;


    }

    swiper-image {
        max-width: 320px;
    }



}