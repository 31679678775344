@media screen and (max-width: 600px) {
    .about-layout {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }


}

/* @media screen and (max-width: 1000px) {

    .about-layout {
        display: flex;
        justify-content: center;
        align-items: center;


    }


} */