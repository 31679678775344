.promote-container {
    width: 1450px;
    height: 502px;
    position: absolute;
    top: 220vh;

}

.promote-img-container {
    width: 785px;
    height: 450px;

}

.promote-img-bg {
    width: 500px;
    height: 500px;

}

.promote-text-container {
    width: 709px;
    height: 470px;

}

@media screen and (max-width: 600px) {
    .promote-container {
        width: 80vw;
        height: 100%;
        /* height: 777.4px; */
        position: absolute;
        top: 300rem;

    }

    .promote-img-container {
        width: 90vw;
        height: 450px;
        position: absolute;
        top: -50px;

    }

    .promote-img-bg {
        width: 70vw;
        height: 290px;


    }

    .promote-img-sm {
        width: 80vw;
        height: 40vh;
        position: absolute;
        top: -67px;

    }

    .promote-text-container {
        width: 80vw;
        height: 296px;
        text-align: center;
        position: absolute;
        top: 340px;

    }

    .promote-text-h1-sm {
        font-size: x-large;
        margin: 5px;
    }

    .promote-text-h2-sm {
        font-size: large;
        margin-left: 5px;
        margin-right: 5px;
    }

    .btn-center-sm {
        margin-top: 10vh;
        margin-left: 20vw;
    }


}


@media screen and (min-width: 700px) {

    .promote-container {
        width: 90%;
        height: 90%;
        margin-top: 30rem;

    }

    .promote-img-container {
        width: 785px;
        height: 450px;

    }

    .promote-img-bg {
        width: 500px;
        height: 500px;

    }

    .promote-text-container {
        width: 709px;
        height: 470px;

    }

}

@media screen and (min-width: 1024px) {

    .promote-container {
        width: 95%;
        height: 502px;
        top: 1400px;

    }

    .promote-img-container {
        width: 785px;
        height: 450px;

    }

    .promote-img-bg {
        width: 500px;
        height: 500px;

    }

    .promote-text-container {
        width: 709px;
        height: 470px;

    }

}