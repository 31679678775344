@media screen and (max-width: 600px) {
    .about-left-background {
        height: 400px;
        width: 90%;
        margin-top: 20px;

    }

    .img-top {
        height: 150px;
        width: 200px;
        position: absolute;
        top: 70px;
        right: 7px;

    }

    .img-top-bg {
        height: 150px;
        width: 200px;
        position: absolute;
        top: 50px;
        right: 20px;

    }

    .img-bottom {
        height: 150px;
        width: 200px;
        position: absolute;
        bottom: 10px;
        left: 7px;

    }
}


@media screen and (max-width: 700px) {

    .about-img-container-tab {


        width: 90%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: aqua;
    }


    .img-bottom {
        height: 150px;
        width: 200px;
        position: absolute;
        bottom: 10px;
        left: 7px;

    }

    /* .about-img-position-tab {
        display: flex;
        align-items: center;
        justify-content: center;
    } */



}