.tippy-tooltip {
    background-color: white;
    border-radius: 25px;
    color: black;

}


.tippy-popper[x-placement^=top] [x-arrow] {
    border-top: 7px solid white;
}