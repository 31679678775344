.why-us-img-bg {
    height: 390px;
    width: 600px;
}

/* .image-container {
    height: 45vh;
    width: 45vw;

} */
.image-container {
    height: 50vh;
    width: 100%;




}

@media screen and (max-width: 600px) {
    .image-container {
        height: 50vh;
        width: 80vw;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .why-us-img-bg {
        height: 260px;
        width: 320px;


    }

    .box-sm {
        height: 99px;
        width: 130px;

    }

    .box-texthead-sm {
        font-size: large;

    }

    .image-position-sm {
        position: absolute;
        top: -60px;
        /* top: 0px; */
    }

    .orangebox-position-sm {
        position: absolute;
        top: 200px;
        left: -15px;
        /* top: 0px;
        left: 0px; */
    }

    .bluebox-position-sm {
        position: absolute;
        bottom: -40px;
        left: 205px;
        /* bottom: 0px;
        left: 0px; */
    }

    .pinkbox-position-sm {
        position: absolute;
        top: -90px;
        left: -20px;

    }
}

@media screen and (min-width: 700px) {
    .image-container {
        height: 90vh;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 20vh;
        left: 200px;


    }

    .why-us-img-bg {
        height: 40vh;
        width: 70vw;

    }


}

@media screen and (min-width: 764px) {
    .image-container {
        height: 90vh;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 400px;
        position: absolute;
        top: 15vh;
        left: 280px;



    }

    .why-us-img-bg {
        height: 38vh;
        width: 70vw;

    }


}

@media screen and (min-width: 1024px) {

    .image-container {
        height: 50vh;
        width: 50%;
        position: absolute;
        top: -40rem;
        left: 0px;



    }

    .why-us-img-bg {
        height: 390px;
        width: 600px;
        background-color: rgb(234 179 8);
    }

}